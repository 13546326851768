<template>
  <div class="Op_manage">
    <div class="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定清空所有日志吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
        <p class="search">
        <el-input
          type="text" v-if="permissoin.includes('query')"
          size="mini"
          clearable
          v-model="searchForm.name"
          placeholder=" 请输入关键字"
        ></el-input>
        <el-date-picker
        v-if="permissoin.includes('query')"
            size="mini"
            v-model="searchForm.date"
            type="datetimerange"
            range-separator="至"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        <button class="btn1" @click="currentPage2=1;getData()" v-if="permissoin.includes('query')">
          <i class="el-icon-search"></i>
          查询
        </button>
        <button class="btn2" @click="dialogVisible=true" v-if="permissoin.includes('del')">
          <i class="el-icon-delete"></i>
          清空
        </button>
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border max-height='780px'>
            <el-table-column type="index" label="序号" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="title" label="日志名称" 
            width="300"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="brower" label="访问浏览器" 
             width="220"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="host" label="访问主机" >
            </el-table-column>
            <el-table-column prop="httpMethod" label="请求方式" >
            </el-table-column>
            <el-table-column prop="executeTime" label="执行时间(ms)" >
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="bottom">
                    <div style="color:##606266;font-size:12px;margin-bottom:8px">开始时间:{{timestampToTime(scope.row.startTime)}}</div>
                    <div style="color:##606266;font-size:12px">结束时间:{{timestampToTime(scope.row.endTime)}}</div>
                    <span slot="reference" style="cursor: pointer;">{{scope.row.executeTime}}</span>
                  </el-popover>
                 </template>
            </el-table-column>
            <el-table-column prop="operatingSystem" label="操作系统" 
            width="220"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="username" label="操作人" >
            </el-table-column>
            <el-table-column prop="errorCode" label="状态码" >
              <template slot-scope="scope">
                <!-- {{ scope.row.online==1?'在线':'离线' }} -->
                <el-button type="success" plain v-if="scope.row.errorCode==200" size="mini">成功</el-button>
                <el-button type="warning" plain v-else size="mini">异常</el-button>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          @size-change="handleSizeChange"
          prev-text="上一页"
          next-text="下一页"
          :page-size="pageSize"
          :page-sizes="[5,10,15,20]"
          layout="total,sizes, prev, pager, next, jumper "
          :total="total"
        >
        </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import { erpGetList,erpdropLogs
} from '@/api/apis.js'
import { timestampToTime } from "@/utils/getTime.js";
export default {
  data() {
    return {
      option1: [],
      form: {},
      timestampToTime,
      info: 1,
      currentPage2:1,
      dialogVisible:false,
      pageSize:10,
      hide:1,
      keysArr:[],
      modal:0,
      total:0,
      permissoin:['get'],
      searchForm:{},
      ruleForm:{
        pwd:123456,
        date1: '',
          date2: '',
      },
       options: [],
        props: {
        label: "name",
        children: "children",
      },
      treedataNew:[],
      tableData: [],
        rules: {
          name: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          roleCode: [
            { required: true, message: '请输入角色编码', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={
        searchText:this.searchForm.name,
        pageSize:this.pageSize,
        pageNum:this.currentPage2,
        startTimes:this.searchForm.date?this.searchForm.date[0]:'',
        endTimes: this.searchForm.date?this.searchForm.date[1]:'',
      }
      erpGetList(data).then(res=>{
        if(res.code==200){
        this.tableData=res.data.list
        this.total=res.data.total-0

      }else{
        this.tableData=[]   
        this.total=0  
      }
      })},

    handleCurrentChange(v){this.currentPage2=v
      this.getData()},
    handleSizeChange(v){
      this.pageSize=v
      this.getData()
    },
    deletePro(){
        erpdropLogs().then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
            this.dialogVisible=false
            this.getData()
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
  },
  mounted() {
    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    this.getData()
  },
};
</script>

<style lang="less" >
.Op_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 208px;
        margin-right: 20px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      // width: 456px;
      // height: 316px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px  #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center{
        padding: 0 30px;
        padding-top: 10px;
          .text_item{
            margin-bottom: 12px;
            &>span:nth-child(1){
              color: #F56C6C;
              margin-right: 2px;
            }
            &>span:nth-child(2){
              display: inline-block;
              width: 60px;
            }
          }
          .el-form{
            .el-form-item__label{
              font-size: 12px;
              color: #222328;
            }
            .el-form-item{
              margin-bottom: 12px;
            }
            .el-input{
              width: 270px;
            }
            .el-form-item__error{
              padding-top: 0px;
            }
            // .el-form-item__content{
            //   margin-left: 0px !important;
            // }
            .two_item{
              display: flex;
              .ti_label{
                display: inline-block;
                width: 80px;
              }
              .ti_label1{
                display: inline-block;
                width: 55px;
                margin-left: 14px;
                margin-right: 2px;
              }
              .el-input{
              width: 80px;
            }
            .little{
              font-size: 8px;
            }
            }
          }
          .input_item{
              margin-bottom: 24px;
              
              &>span:nth-child(1){
                color: #F56C6C;
                margin-right: 2px;
              }
              &>span:nth-child(2){
                display: inline-block;
                margin-right: 8px;
              }
              .el-input{
                width: 270px;
              }
            }
          .mc_right{
            width: 400px;
            .mcr_title{
              margin-top: 18px;
              margin-bottom: 22px;
              i{
                font-size: 14px;
                color:#f37d00 ;
              }
            }
            .input_item{
              margin-bottom: 24px;
              
              &>span:nth-child(1){
                color: #F56C6C;
                margin-right: 2px;
              }
              &>span:nth-child(2){
                width: 60px;
                display: inline-block;
                margin-right: 8px;
              }
              .el-input{
                width: 270px;
              }
            }
            .jwd{
              display: flex;
              justify-content: space-between;
              font-size: 8px;
              line-height: 18px;
              margin-top: -24px;
              width: 350px;
              box-sizing: border-box;
              padding: 0 6px;
              &>span:nth-child(2){
                color: #0090ff;
              }
            }
            .map{
              // background-color: rgb(116, 35, 35);
              width: 360px;
              height: 310px;
            }
          }
        
      }
      .modal_center1{
        padding: 0 30px;
        padding-top: 10px;
        min-width: 300px;
        min-height: 300px;
      }
      .bottom{
        height: 54px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        &>button:nth-child(1){
          margin-right: 20px;
        }
      }
    }
   }
}
</style>